import React from "react"
import ComplaintsMobile from "../../../../components/body/pages/mobile/uk/legal/complaints"
import NoheaderFooter from "../../../../components/Noheaderfooter"
import SEO from "../../../../components/seo"

const ComplaintPage = () => (
  <NoheaderFooter>
    canonical={"https://kuda.com/en-gb/legal/complaints-policy"}
    <SEO title="Privacy Policy | Kuda | The Money App for Africans" />
    <ComplaintsMobile />
  </NoheaderFooter>
)

export default ComplaintPage
